import React from "react";
import "./Rodape.css";
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaCcDiscover,
  FaInstagram,
  FaWhatsapp,
  FaLock,
} from "react-icons/fa";
import { SiPix } from "react-icons/si";
import { MdLocalShipping } from "react-icons/md";
import correios from "./IMG/correios.png";
import ssl from "./IMG/ssl.png";

const Rodape = () => {
  return (
    <footer className="rodape">
      <div className="rodape-pagamento-seguranca-envio">
        <div className="rodape-pagamento">
          <h3>Formas de Pagamento</h3>
          <div className="bandeiras-pagamento">
            <FaCcVisa />
            <FaCcMastercard />
            <FaCcAmex />
            <FaCcDiscover />
            <SiPix className="pix-icon" />
          </div>
        </div>

        <div className="rodape-seguranca">
          <h3>Segurança</h3>
          <div className="seguranca-icones">
            <FaLock className="shipping-icon" />
            <img src={ssl} alt="SSL" className="ssl-icon" />
          </div>
        </div>

        <div className="rodape-envio">
          <h3>Formas de Envio</h3>
          <div className="bandeiras-envio">
            <MdLocalShipping className="shipping-icon" />
            <img src={correios} alt="Correios" className="correios-logo" />
          </div>
        </div>
      </div>

      <div className="rodape-contato">
        <h3>Contato</h3>
        <p>
          <strong>E-mail:</strong> contatostilofitness@gmail.com
        </p>
      </div>

      <div className="rodape-social">
        <h3>UA Social</h3>
        <div className="social-icons">
          <a
            href="https://wa.me/5516982061613"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp className="social-icon" />
            <span>WhatsApp</span>
          </a>
          <a
            href="https://www.instagram.com/stilofitnessbeach/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="social-icon" />
            <span>Instagram</span>
          </a>
        </div>
      </div>

      <div className="rodape-info">
      <h3>2024 Stilo Fitness</h3>
        <p>2024 Stilo Fitness - Todos os direitos reservados.</p>
        <p>© 2024 CodeX - Especialista em Lojas Virtuais</p>
      </div>
    </footer>
  );
};

export default Rodape;
