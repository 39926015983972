import React, { useEffect, useState } from 'react';

function TextoRotativo() {
  const [index, setIndex] = useState(0);
  const mensagens = [
    "5% OFF no PIX à vista, confira!",
    "Site oficial - www.stilofitness.com.br"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % mensagens.length);
    }, 3000); // Troca de mensagem a cada 3 segundos

    return () => clearInterval(interval);
  }, [mensagens.length]);

  const handlePrev = () => {
    setIndex((prevIndex) => (prevIndex - 1 + mensagens.length) % mensagens.length);
  };

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex + 1) % mensagens.length);
  };

  // Função para formatar a mensagem com 'Site oficial' em negrito
  const formatarMensagem = (mensagem) => {
    if (mensagem.includes('Site oficial')) {
      const partes = mensagem.split(' - ');
      return (
        <span>
          <span className="negrito">{partes[0]}</span> - {partes[1]}
        </span>
      );
    }
    return mensagem;
  };

  return (
    <div className="texto-rotativo">
      <button className="prev-button" onClick={handlePrev}>{'<'}</button>
      <h1>{formatarMensagem(mensagens[index])}</h1>
      <button className="next-button" onClick={handleNext}>{'>'}</button>
      <hr className="linha-branca" />
    </div>
  );
}

export default TextoRotativo;
